<template>
  <section class="install-section">
    <h2 class="page-title" v-html="$t('main.install.title')" />

    <div class="install-block">
      <div class="install-item install-item_1">
        <div class="install-item__num">1</div>
        <div class="install-item__text" v-html="$t('main.install.text1')" />
        <div class="install-item__btns">
          <a
            href="https://t.me/avoVPN_bot"
            target="_blank"
            rel="noopener noreferrer"
            class="btn btn_sm"
          >
            <svg
              width="26"
              height="22"
              viewBox="0 0 26 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="btn__icon"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.78736 9.50233C8.76667 6.46155 13.4206 4.45689 15.7493 3.48834C22.3979 0.722916 23.7795 0.242533 24.68 0.226671C24.878 0.223182 25.3208 0.272264 25.6077 0.505018C25.8499 0.70155 25.9165 0.967037 25.9484 1.15337C25.9803 1.33971 26.02 1.76418 25.9884 2.09585C25.6281 5.8815 24.0692 15.0683 23.276 19.3083C22.9404 21.1024 22.2796 21.7039 21.6399 21.7628C20.2496 21.8907 19.1938 20.844 17.8473 19.9613C15.7401 18.58 14.5498 17.7202 12.5044 16.3724C10.1407 14.8147 11.673 13.9586 13.0201 12.5594C13.3726 12.1933 19.4983 6.62147 19.6169 6.11602C19.6317 6.05281 19.6455 5.81717 19.5055 5.69275C19.3655 5.56832 19.1589 5.61087 19.0098 5.64471C18.7985 5.69268 15.4323 7.91758 8.91132 12.3194C7.95585 12.9755 7.09041 13.2952 6.31501 13.2785C5.46019 13.26 3.81586 12.7951 2.59347 12.3978C1.09416 11.9104 -0.0974649 11.6527 0.00629874 10.825C0.0603452 10.3939 0.654034 9.95302 1.78736 9.50233Z"
                fill="currentColor"
              />
            </svg>

            <div class="btn__text">{{ $t('main.install.btn') }}</div>
          </a>
        </div>
      </div>
      <div class="install-item install-item_2">
        <div class="install-item__num">2</div>
        <div class="install-item__text" v-html="$t('main.install.text2')" />
      </div>
      <div class="install-item install-item_3">
        <div class="install-item__num">3</div>
        <div class="install-item__text" v-html="$t('main.install.text3')" />
        <div class="install-item__btns">
          <a
            href="https://play.google.com/store/apps/details?id=com.v2ray.ang&hl=ru&pli=1"
            target="_blank"
            rel="noopener noreferrer"
            class="btn btn_sm"
          >
            <svg
              width="19"
              height="28"
              viewBox="0 0 19 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="btn__icon"
            >
              <path
                d="M0 18.9259C0 23.9815 4.01852 28 9.07407 28C14.1296 28 18.1481 23.9815 18.1481 18.9259V13.7407H0V18.9259ZM14.3889 3.88889L17.1111 1.16667L16.0741 0.12963L13.0926 3.11111C11.9259 2.46296 10.5 2.07407 9.07407 2.07407C7.64815 2.07407 6.22222 2.46296 5.05556 2.98148L2.07407 0L1.03704 1.16667L3.75926 3.88889C1.42593 5.44444 0 8.16667 0 11.1481V12.4444H18.1481V11.1481C18.1481 8.16667 16.7222 5.44444 14.3889 3.88889ZM5.18518 9.85185C4.40741 9.85185 3.88889 9.33333 3.88889 8.55556C3.88889 7.77778 4.40741 7.25926 5.18518 7.25926C5.96296 7.25926 6.48148 7.77778 6.48148 8.55556C6.48148 9.33333 5.96296 9.85185 5.18518 9.85185ZM12.963 9.85185C12.1852 9.85185 11.6667 9.33333 11.6667 8.55556C11.6667 7.77778 12.1852 7.25926 12.963 7.25926C13.7407 7.25926 14.2593 7.77778 14.2593 8.55556C14.2593 9.33333 13.7407 9.85185 12.963 9.85185Z"
                fill="currentColor"
              />
            </svg>

            <div class="btn__text">Android</div>
          </a>
          <a
            href="https://apps.apple.com/ru/app/v2box-v2ray-client/id6446814690"
            target="_blank"
            rel="noopener noreferrer"
            class="btn btn_sm"
          >
            <svg
              width="24"
              height="28"
              viewBox="0 0 24 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="btn__icon"
            >
              <path
                d="M22.3256 9.54615C22.1624 9.67217 19.2808 11.2879 19.2808 14.8807C19.2808 19.0363 22.9475 20.5065 23.0572 20.5429C23.0403 20.6325 22.4747 22.5563 21.124 24.5165C19.9196 26.2414 18.6617 27.9636 16.7481 27.9636C14.8346 27.9636 14.3421 26.8575 12.1331 26.8575C9.98038 26.8575 9.21497 28 7.46463 28C5.7143 28 4.49301 26.4038 3.0888 24.4436C1.46229 22.1418 0.148132 18.5659 0.148132 15.1719C0.148132 9.72817 3.70508 6.84108 7.20574 6.84108C9.06582 6.84108 10.6164 8.05641 11.7842 8.05641C12.8957 8.05641 14.6292 6.76828 16.7453 6.76828C17.5473 6.76828 20.4289 6.84108 22.3256 9.54615ZM15.7407 4.46365C16.6159 3.43034 17.235 1.9966 17.235 0.562856C17.235 0.364036 17.2181 0.162416 17.1815 0C15.7576 0.0532053 14.0636 0.943695 13.0421 2.12261C12.2401 3.0299 11.4915 4.46365 11.4915 5.91699C11.4915 6.13541 11.5281 6.35384 11.545 6.42384C11.635 6.44064 11.7814 6.46025 11.9277 6.46025C13.2053 6.46025 14.8121 5.60896 15.7407 4.46365Z"
                fill="currentColor"
              />
            </svg>

            <div class="btn__text">iOS</div>
          </a>
        </div>
      </div>
      <div class="install-item install-item_4">
        <div class="install-item__num">4</div>
        <div
          class="install-item__text install-item__text_short"
          v-html="$t('main.install.text4')"
        />
      </div>

<!--      <div class="install-video install-video_1">-->
<!--        <div class="install-video__wrapper">-->
<!--          <iframe width="560" height="315" src="https://www.youtube.com/embed/iu8_dce0fA0?si=sxF9PQiGbT9Tqe72" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>-->
<!--        </div>-->
<!--        <div class="install-video__text">{{ $t('main.install.video_1_text') }}</div>-->
<!--      </div>-->

<!--      <div class="install-video install-video_2">-->
<!--        <div class="install-video__wrapper">-->
<!--          <iframe width="560" height="315" src="https://www.youtube.com/embed/W87XrD0s268?si=6CoeDJJp32u6ngnT" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>-->
<!--        </div>-->
<!--        <div class="install-video__text">{{ $t('main.install.video_2_text') }}</div>-->
<!--      </div>-->
    </div>
  </section>
</template>

<script>
export default {
  name: 'InstallBlock'
}
</script>
